import React, { useState, useRef } from 'react'
import hero from '../../assets/clouds-2.png'
import './mint.css'
import MintCard from './mintCard'
import SaleStatus from './saleStatus'
import Web3 from 'web3'
import Web3EthContract from 'web3-eth-contract'
import contractAbi from '../../contract/SaleERC721A (3).json'
import { useEffect } from 'react'
import bgImg from '../../assets/clouds-1.png'
import { ImCross } from 'react-icons/im'
import claimContractAbi from '../../contract/ClaimTrippyFrens3D (1).json'

const web3 = new Web3(window.ethereum)

const abi = contractAbi.abi

// const contractAddress = '0xF34D53f6425d44f554c9671F7E12c02a13E7c52f'
const contractAddress = '0x52BbFADd3a0D24F76A9E78A5e6Fd08daB65DaA14'

const claimContractAddress = '0x1aA4F09aea42AF71996C52393E8bfF4fAcAECf34'

Web3EthContract.setProvider(window.ethereum)

const frensListContract = new Web3EthContract(abi, contractAddress)
const claimContract = new web3.eth.Contract(
  claimContractAbi.abi,
  claimContractAddress,
)

const MintSection = () => {
  const [changeCard, setChangeCard] = useState('wallet')
  const [walletAddress, setWalletAddress] = useState('')
  const [count, setCount] = useState(null)
  let [limit, setLimit] = useState(null)
  let [price, setPrice] = useState(null)
  let [listMsg, setListMsg] = useState(null)
  let [loading, setLoading] = useState(false)
  let [chainIdMsg, setChainIdMsg] = useState(null)
  let [myAddress, setMyAddress] = useState('')
  let [status, setStatus] = useState(null)
  let [success, setSuccess] = useState(false)
  // let [ethPrice, setEthPrice] = useState(0.05)
  let [statusMsg, setStatusMsg] = useState(null)
  let [whiteList, setWhiteList] = useState(null)

  // connect wallet function
  const handleConnectWallet = async () => {
    setLoading(true)

    const { ethereum } = window
    const metamaskIsInstalled = ethereum

    if (metamaskIsInstalled) {
      const chainId = await web3.eth.getChainId()

      //  const chainId = await ethereum.on('eth_chainId', (chainId) => {
      //     /* handle the chainId */
      //     console.log(chainId)
      //     return chainId
      //   });
      console.log(chainId)
      // check chainId
      if (chainId === 1) {
        setChainIdMsg(null)
        try {
          const account = await ethereum.request({
            method: 'eth_requestAccounts',
          })
          setWalletAddress(account[0])
          await getNftData(account[0])
        } catch (err) {
          console.log(err)
          setLoading(false)
          // console.log(isListed,phase,price)
        }
      } else {
        setLoading(false)
        setChainIdMsg('Please Switch your account to Ethereum Mainnet')
      }
    } else {
      alert('Please install MetaMask')
    }
  }

  // switch network
  const switchNetwork = async (network) => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
      })
      setChainIdMsg(null)
      setSwitchBtn(false)
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: network.chainId,
                rpcUrls: network.rpcUrls,
                chainName: network.chainName,
                nativeCurrency: network.nativeCurrency,
                blockExplorerUrls: network.blockExplorerUrls,
              },
            ],
          })
          setChainIdMsg(null)
        } catch (addError) {
          alert('Error occurred')
        }
      } else {
        throw switchError
      }
    }
  }

  // get nft data using contract abi function
  const getNftData = async (account) => {
    const isListed = await frensListContract.methods
      .isWhitelisted(account || walletAddress)
      .call()
    const phase = await frensListContract.methods.phase().call()
    const nftPrice = await frensListContract.methods.price().call()
    const nftCount = await frensListContract.methods.count().call()
    const nftLimit = await frensListContract.methods.limit().call()
    getTokenClaimCount()
    setWhiteList(isListed)
    setLoading(false)
    setChangeCard('mint')
    setStatus(phase)
    setCount(nftCount)
    setLimit(nftLimit)
    setPrice(Number(nftPrice))
  }

  const checkFrensList = async () => {
    const isListed = await frensListContract.methods
      .isWhitelisted(myAddress)
      .call()
    if (isListed) {
      setListMsg('Success , you are on frens list')
    } else {
      setListMsg('Failed , you are not on frens list')
    }
  }

  //  nft minting function

  // nft counter
  let [mintLoad, setMintLoad] = useState(false)

  const [val, setVal] = useState(1)
  const handleAdd = () => {
    if (val < 10) {
      setVal(val + 1)
    }
  }

  const handleMinus = () => {
    if (val > 1) {
      setVal(val - 1)
    }
  }
  // mint function
  const nftMint = async () => {
    setMintLoad(true)
    let totalPrice = price * val
    const chainId = await web3.eth.getChainId()
    // set up transaction parametres
    const transactionParameters = {
      to: contractAddress,
      from: walletAddress,
      value: String(totalPrice),
      data: frensListContract.methods.purchase(val).encodeABI(),
    }

    // calling smart contract mint function
    if (chainId === 1) {
      // check sale avail or not
      if (status === '2') {
        if (Number(count) < 8888) {
          setChainIdMsg(null)
          frensListContract.methods
            .purchase(val)
            .send(transactionParameters)
            .then((rs) => {
              setMintLoad(false)
              setChangeCard('')
              setSuccess(true)
            })
            .catch((err) => {
              setMintLoad(false)
              console.log(err)
            })
        } else if (Number(count) >= 8888) {
          setChangeCard('')
          setSuccess(false)
        }
      } else if (status === '1') {
        if (whiteList) {
          setChainIdMsg(null)
          frensListContract.methods
            .purchase(val)
            .send(transactionParameters)
            .then((rs) => {
              setMintLoad(false)
              setChangeCard('')
              setSuccess(true)
            })
            .catch((err) => {
              setMintLoad(false)
              console.log(err)
            })
        } else {
          setStatusMsg('Sorry you are not in Frens list')
          setMintLoad(false)
        }
      } else {
        setStatusMsg('Sorry minting closed')
        setMintLoad(false)
        setChangeCard('')
      }
    } else {
      setMintLoad(false)
      setChainIdMsg('Please Switch your account to Ethereum Mainnet')
    }
  }

  // whitelist address input function
  const handleSetAdderess = (e) => {
    setMyAddress(e.target.value)
    if (e.target.value === '') {
      setListMsg(null)
    }
  }

  const handleReset = () => {
    setMyAddress('')
    setListMsg(null)
  }

  // onmousemove img animation
  const imgRef = useRef(null)
  useEffect(() => {
    const bgImg = document.getElementById('bgImg')
    const heroImg = document.getElementById('heroImg')
    document.addEventListener('mousemove', (e) => {
      let _mouseX = e.clientX
      let _mouseY = e.clientY
      let _depth1 = `${_mouseX * 0.001}%, ${_mouseY * 0.002}%`
      let _depth3 = `${_mouseX * 0.004}%, ${_mouseY * 0.005}%`
      let s1 = `${_depth1}`
      let s3 = `${_depth3}`
      bgImg.style.transform = 'translate(' + s1 + ')'
      if (heroImg) {
        heroImg.style.transform = 'translate(' + s3 + ')'
      }
    })
  })

  // claim token working

  let [tokenId, setTokenId] = useState('')
  let [claimedTokens, setClaimedToken] = useState(0)
  let [claimLoading, setClaimLoading] = useState(false)
  let [confirmTransaction, setTransactionConfirm] = useState(false)
  let [lenghtMsg, setLenghtMsg] = useState(false)
  let [showSwitchBtn, setSwitchBtn] = useState(false)

  const handleTokenId = (e) => {
    setTokenId(e.target.value)
  }

  const getTokenClaimCount = () => {
    // calling token count function
    claimContract.methods
      .count()
      .call()
      .then((res) => {
        setClaimedToken(res)
      })
  }

  // token claim function
  const handleClaim = async () => {
    const chainId = await web3.eth.getChainId()
    setClaimLoading(true)
    let removeComma = tokenId.split(',')
    let convertIntoNum = removeComma.map(Number)
    // check chain id
    if (chainId === 1) {
      setSwitchBtn(false)
      // check input empty or not
      if (tokenId !== '') {
        // check input token id length
        if (convertIntoNum.length < 11) {
          setLenghtMsg(false)
          claimContract.methods
            .claim(convertIntoNum)
            .send({ from: walletAddress })
            .then((res) => {
              // console.log(res)
              setClaimLoading(false)
              getTokenClaimCount()
              setTokenId('')
              setTransactionConfirm(true)
            })
            .catch((err) => {
              // console.log(err)
              setClaimLoading(false)
            })
        } else {
          setLenghtMsg(true)
          setClaimLoading(false)
        }
      } else {
        setClaimLoading(false)
      }
    } else {
      setSwitchBtn(true)
      setClaimLoading(false)
    }
  }

  return (
    <div className="mintContainer">
      {/* claim trippy box  */}
      {walletAddress ? (
        <div className="clTkn">
          {!confirmTransaction ? (
            <>
              <div className="numOfClaim">
                <p>{claimedTokens} / 1000</p>
                <p className="tknPara">
                  Own a TrippyFren? Enter your tokenId to claim a free
                  TrippyFren 3D if you own multiple please input them seperated
                  by a comma example 10 , 17 , 55
                </p>
              </div>
              <div className="inptClaim">
                <input
                  type="text"
                  value={tokenId}
                  onChange={handleTokenId}
                  placeholder="Example 10 , 17 , 55"
                />
                {/* show input length error  */}
                {lenghtMsg ? (
                  <p className="errorMsg">
                    You can not transact more than 10 Trippy at a time.
                  </p>
                ) : null}
                {/* show switch btn error  */}
                {showSwitchBtn ? (
                  <>
                    <p className="chainMsgTk">
                      Please Switch your account to Ethereum Mainnet
                    </p>
                    <button className="switchBtn" onClick={switchNetwork}>
                      Switch Network
                    </button>
                  </>
                ) : null}
                {/* claim btn  */}
                <button
                  className="mnBtn"
                  disabled={claimLoading}
                  onClick={handleClaim}
                >
                  {claimLoading ? 'loading...' : 'Claim free Trippy'}
                </button>
              </div>
            </>
          ) : (
            // confirm transaction msg
            <div className="confrimMsg">
              <p>Transaction confirmed! Thank you for being a fren 👁🔥👁</p>
              <a
                href="https://opensea.io/collection/trippy-frens-3d"
                className="opnseaBtn"
                target="_blank"
                rel="noopener noreferrer"
              >
                OpenSea
              </a>
            </div>
          )}
        </div>
      ) : null}
      <div className="mintSection" id="module" ref={imgRef}>
        <img className="bgImg" id="bgImg" src={bgImg} alt="..." />
        {changeCard === 'wallet' ? (
          <div className="connectWallet">
            <div className="heroImg">
              <img src={hero} id="heroImg" alt="..." />
            </div>
            <button
              disabled={loading}
              className="connectBtn"
              onClick={handleConnectWallet}
            >
              <p>{loading ? ' loading...' : 'Connect   wallet'}</p>
            </button>

            {chainIdMsg === null ? null : (
              <>
                <p className="chainMsg">{chainIdMsg}</p>
                <button className="switchBtn" onClick={switchNetwork}>
                  Switch Network
                </button>
              </>
            )}
          </div>
        ) : changeCard === 'mint' ? (
          <MintCard
            count={count}
            limit={limit}
            price={price}
            address={walletAddress}
            val={val}
            handleAdd={handleAdd}
            handleMinus={handleMinus}
            handleMint={nftMint}
            loading={mintLoad}
            chaingMsg={chainIdMsg}
            onClick={switchNetwork}
            status={status}
            statusMsg={statusMsg}
            claimedTokens={claimedTokens}
          />
        ) : (
          <SaleStatus
            limit={limit}
            count={count}
            status={status}
            success={success}
          />
        )}
      </div>
      {changeCard === 'wallet' ? (
        <div className="frensListCheck">
          <div className="frensHead">
            <h1>Frens list check</h1>
          </div>
          <div className="guideTOCheck">
            <p>
              Add your wallet address below and click "Check my address" to
              check your Fren List status.
            </p>
          </div>
          <div className="adresInp">
            <div className="inpDiv">
              <input
                type="text"
                name="address"
                id="address"
                onChange={handleSetAdderess}
                value={myAddress}
                placeholder="0x0000..."
              />
              <ImCross className="crosBtn" onClick={handleReset} />
            </div>
            {listMsg === null || listMsg === '' ? (
              <button onClick={checkFrensList}>Check my address</button>
            ) : (
              <p>{listMsg}</p>
            )}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default MintSection
