import React from "react";
import "./steps.css";

const StepsContract = () => {
  return (
    <div className="cusContainer" id="Roadmap">
      <div className="stepsSection">
        <div className="stepsContent">
          <div className="stepsHead">
            <h1>Verified smart contract</h1>
          </div>
          {/* <div className="minGuidPara">
            <p>

            Minting with a Ledger? Be sure that both "Blind Signing" and "Debug Data" are turned on.
            </p>
          </div> */}
          <div className="mintSteps">
            <ol>
              <li>
                <h1>1.</h1> <h1> Connect wallet</h1>
              </li>
              <li>
                <h1>2.</h1> <h1> Choose amount *up to 3 per wallet</h1>
              </li>
              <li>
                <h1>3.</h1> <h1>PRESS MINT NOW & CONFIRM</h1>
              </li>
            </ol>
          </div>
          <div className="cpRight">
            <p>
              Trippy Frens Ⓒ {" " + new Date().getFullYear() + " "} All rights
              reserved{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsContract;
