import React from 'react'
import gif from '../../assets/little_fren1.gif'
import placeVideo from '../../assets/bike_mov_1 (1).mp4'

const MintCard = ({
  chaingMsg,
  count,
  limit,
  address,
  handleAdd,
  handleMinus,
  val,
  handleMint,
  loading,
  onClick,
  status,
  statusMsg,
  claimedTokens
}) => {

  return (
    <div className="mintCard">
      <div className="mintCardContent">
        <div className="ssContent">
          <h2>Sale Status</h2>
          <h4>
            {status === '0' ? 'Closed' : status === '1' ? 'pre-sale' : 'Public'}
          </h4>
          <p>{address}</p>
        </div>
        <div className="mintDetail">
          <div className="nftImgCard">
            <img src={gif} className="mobGif" alt="..." />
            <video
              className="deskVideo"
              src={placeVideo}
              typeof="video/mp4"
              autoPlay
              loop
            ></video>

            <p className="price">
              Total price: {(0.05 * val).toString().slice(0, 4)} eth
            </p>
            <div className="counter">
              <button onClick={handleMinus} disabled={loading}>
                -
              </button>
              <p className="incValue">{val}</p>
              <button onClick={handleAdd} disabled={loading}>
                +
              </button>
            </div>
          </div>
          <h3>{ Number(count) + Number(claimedTokens)}/5000</h3>
        </div>
      </div>
      {statusMsg === null ? null : (
        <>
          <p className="chainMsg">{statusMsg}</p>
        </>
      )}
      {chaingMsg === null ? null : (
        <>
          {' '}
          <p className="chainMsg">{chaingMsg}</p>
          <button className="switchBtn" onClick={onClick}>
            Switch Network
          </button>
        </>
      )}
      <button className="mnBtn" onClick={handleMint} disabled={loading}>
        {loading ? 'loading...' : 'Mint now'}
      </button>
    </div>
  )
}

export default MintCard
