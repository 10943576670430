import './App.css';
import Header from './Component/Header/Header';
import MintSection from './Component/mintSection';
import StepsContract from './Component/StepsContract/StepsContract';

function App() {
  return (
    <div className="App">
      <Header />
      <MintSection />
      <StepsContract />
      <div className="cpRightMain">
        <p>Copyright © {' '+new Date().getFullYear()+' '}, All Right Reserved <a
          href="https://smashcode.dev/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Smash Code
        </a></p>
        
      </div>
    </div>
  );
}

export default App;
