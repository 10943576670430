import React from 'react'

const SaleStatus = ({ success, limit, count, status }) => {
  return (
    <div className="ssBox">
      <div className="ssSection">
        <div className="ssContent">
          <h2>Sale Status {count + '/' + 8888}</h2>
              <h4>
                {status === '0'
                  ? 'Closed'
                  : status === '1'
                  ? 'Whitelist'
                  : 'Public'}
              </h4>
          {!success ? (
            <>
              <p>0x3A8F78f0258E154aF4C535E0D4b272133aF8B9c2</p>
            </>
          ) : null}
          <div className="openSeaLink">
            {success ? (
              <>
              <p>Transaction confirmed! Thank you for being a fren 👁🔥👁</p>
                
                </>
            ) : (
              <>
                <h3>Trippy frens are sold out!</h3>
                <p>You can buy from our beloved holders on</p>
              </>
            )}
            <a
                  href="https://opensea.io/collection/trippy-frens-nft"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Opensea
                </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SaleStatus
