import React, { useState } from 'react'
import { HiOutlineMenuAlt1 } from 'react-icons/hi'
// import { NavLink } from "react-router-dom";
import './header.css'
import logo from '../../assets/logo-black.svg'
import clrLogo from '../../assets/logo-colour.svg'
import { FaDiscord } from 'react-icons/fa'
import { BsTwitter, BsInstagram } from 'react-icons/bs'

const Header = () => {
  let [open, setOpen] = useState(false)
  const handleOpenMenu = () => {
    setOpen(true)
  }
  const handleCloseMenu = () => {
    setOpen(false)
  }

  const handleRelode = () => {
    window.location.reload()
  }

  return (
    <div className="headContainer">
      {open ? <div className="overLay" onClick={handleCloseMenu}></div> : null}
      <div className="headMd">
        <div className="headContent">
          <div className="headLogo">
            <a
              href="#"
              onClick={() => {
                handleCloseMenu()
                handleRelode()
              }}
            >
              <img className="blackLogo" src={logo} alt="..." />
              <img className="clrLogo" src={clrLogo} alt="..." />
            </a>
          </div>
          <div className={open ? 'openMenu headLinks' : 'closeMenu headLinks'}>
            <ul className="navLinks">
              <li>
                <a
                  onClick={() => {
                    handleCloseMenu()
                    handleRelode()
                  }}
                  href="#"
                >
                  Home
                </a>
              </li>
              {['about', 'roadmap', 'team'].map((item, i) => (
                <li key={i}>
                  <a
                    href={`https://www.trippyfrens.com/#${item}`}
                    onClick={handleCloseMenu}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            <ul className="socialUl">
              <li>
                <a
                  href="https://twitter.com/trippyfrensnft"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsTwitter />
                </a>
              </li>
              <li>
                <a
                  href="https://discord.com/invite/trippyfrens"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaDiscord />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/trippyfrens/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsInstagram />
                </a>
              </li>
            </ul>
            {/* <p className="mobSideBottm">
              {" "}
              Copyright &copy; {" " + new Date().getFullYear() + " "} All Rights
              Reserved by
              <a href="#"> Smash Code</a>.
            </p> */}
          </div>
          <div className="headSLinks">
            <ul className="sLinks">
              {!open ? (
                <li className="menuBtn">
                  <button onClick={handleOpenMenu}>
                    <HiOutlineMenuAlt1 className="mnIcon" />
                  </button>
                </li>
              ) : (
                <li className="closeBtn">
                  <button onClick={handleCloseMenu}>
                    <span className={open ? 'sp1' : ''}></span>
                    <span className={open ? 'sp2' : ''}></span>
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
